<template>
  <div
    :class="{ 'portfolio-employment': !isLeft, 'portfolio-projects': isLeft }"
  >
    <div
      class="label"
      v-animate-css.once="{
        classes: 'fadeIn',
        duration: fade.duration,
        delay: fade.delay,
      }"
    >
      <h2>{{ title }}</h2>
    </div>
    <ul>
      <li v-for="(img, idx) in containerProjects" :key="idx">
        <portfolioImage
          @click="imageClicked"
          :config="img"
          :isLeft="false"
          :isMobile="isMobile"
          :selected="activePortfolioObject"
          v-animate-css.once="{
            classes: 'fadeIn',
            duration: fade.duration,
            delay: fade.delay + (idx + 1) * 200,
          }"
        ></portfolioImage>
      </li>
    </ul>
  </div>
</template>

<script>
import portfolioImage from "./portfolioImage.vue";
import portfolio from "../mixins/portfolio.js";

export default {
  name: "portfolioContainer",
  components: { portfolioImage },
  mixins: [portfolio],
  computed: {
    containerProjects() {
      if (this.title === "EMPLOYMENT") {
        return this.portfolio.Employment;
      }
      return this.portfolio.Projects;
    },
  },
  props: {
    fade: {
      require: true,
      default: () => {
        return {
          duration: 500,
          delay: 1400,
        };
      },
    },
    title: {
      require: true,
      default: "NEEDS A TITLE",
    },
    isLeft: {
      require: true,
      default: false,
    },
    activePortfolioObject: {
      require: true,
      default: () => {
        return null;
      },
    },
    isMobile: {
      require: true,
      default: false,
    },
  },
  watch: {
    activePortfolioObject(value) {
      console.log("Portfolo Image Select", value);
    },
  },
  data() {
    return {};
  },
  methods: {
    imageClicked(event) {
      const [a, b] = Object.values(this.portfolio).map((v) => v);
      const [clickedImage] = [...a, ...b].filter((v) => v.id === event);
      this.$emit("imageClicked", clickedImage);
    },
  },
};
</script>
