<template>
  <transition name="fade">
    <div v-if="show" :class="{ 'fade-in': initFade, 'is-hidden': initHidden }">
      <div class="content-job-title">
        <h3>{{ content.title }}</h3>
        <template v-if="'dates' in content">
          <h5>{{ content.dates }}</h5>
        </template>
      </div>
      <h4>{{ content.subTitle }}</h4>
      <div class="content-bullets">
        <ul>
          <li
            v-for="(row, index) in content.content"
            :key="index"
            v-html="row"
          ></li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "portfolio-content",
  props: {
    selected: {
      require: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      content: {},
      show: true,
      initFade: false,
      initHidden: true,
    };
  },
  async mounted() {
    this.initHidden = true;
    this.content = this.selected;
    await this.delay(200);
    this.initHidden = false;
    this.initFade = true;
    this.show = true;
    await this.delay(1000);
    this.initFade = false;
  },
  watch: {
    async selected(value) {
      this.show = false;
      await this.delay(200);
      this.content = value;
      this.show = true;
    },
  },
  methods: {
    async delay(duration) {
      return new Promise((resolve) => setTimeout(resolve, duration));
    },
  },
};
</script>

<style scoped>
h3 {
  margin-bottom: 8px;
  font-size: 24px;
}
h4 {
  margin: 8px 0;
}

.content-job-title h3 {
  margin-bottom: 0;
  padding-bottom: 0;
}

.content-job-title h5 {
  color: #00aaff;
  opacity: 0.5;
  display: inline-block;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 860px) {
  .content-job-title h5 {
    font-size: 24px;
  }
}

.content-bullets ul {
  padding: 0 16px;
}
.content-bullets li {
  padding: 8px 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Hack around Mounted() not showing animation */
.is-hidden {
  opacity: 0;
}
.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
