export default {
  data() {
    return {
      portfolio: {
        Employment: [
          {
            desktop: { top: -30, left: 40 },
            mobile: { top: -30, left: 120 },
            src: "employment-loram-technologies-logo.png",
            id: btoa(224),
            title: "Loram Technologies",
            subTitle: "Principal Software Engineer",
            dates: "12/2022 - Present",
            content: [
              "Responsible for developing LORAM's Virtual Rail data infrastructure using PySpark and SQL to extract, transform, and load customer data into the expected format for predictive simulations.",
              "Maintaining and enhancing the front-end framework of LORAM's predictive maintenance platform by performing fixes and improving features based on product definition. ",
              "Led efforts to integrate data infrastructure into LORAM's existing Virtual Rail platform's software architecture, leveraging infrastructure as code when deploying to AWS.",
            ],
          },
          {
            desktop: { top: 60, left: 40 },
            mobile: { top: 65, left: 120 },
            src: "employment-logo-sentient-science.png",
            id: btoa(234),
            title: "Sentient Science",
            subTitle:
              "Principal Software Engineer / Software Development Manager",
            dates: "02/2016 - 11/2022",
            content: [
              "Responsible for the full-stack development of platform features, AWS serverless architecture, bug fixes, and enhancements.",
              "Spearheaded improvements to the software development lifecycle by migrating the legacy front-end to the Vue framework, incorporating user experience, and introducing scrum/agile.",
              "Defined product's technical direction and led recurring sprint releases with the collaboration of product management and department leads.",
              "Experienced in building and managing software engineering teams across different departments.",
              "Managed cloud resources on AWS: code deployments, infrastructure as code, network security, billing, and user management.",
              "Quality Management Representative for ISO 9001:2015 certification.",
            ],
          },
          {
            desktop: { top: 180, left: 55 },
            mobile: { top: 180, left: 120 },
            src: "employment-logo-lovullo.png",
            id: btoa(123),
            title: "LoVullo Associates",
            subTitle: "Jr. Software Developer",
            dates: "02/2014 - 01/2016",
            content: [
              "Responsible for investigating and resolving issues related to application functionality and providing technical support to customers.",
              "Experience implementing all aspects of Model-View-Controller (MVC) within the Symphony framework.",
              "Designed and assisted in developing affiliate company websites, email blasts, marketing reports, and internal dashboards.",
            ],
          },
          {
            desktop: { top: 280, left: 50 },
            mobile: { top: 280, left: 130 },
            src: "employment-logo-nfusion-interactive.png",
            id: btoa(567),
            title: "N-Fusion Interactive, LLC",
            subTitle: "Project Lead / Game Designer / Level Designer",
            dates: "02/2006 - 02/2011",
            content: [
              "Directed team members in design implementation, platform limitations, and tool training to optimize workflow and ensure adherence to project goals.",
              "Spearheaded level layout development in 3ds Max and level editor, scripting combat scenarios and refining gameplay iteratively through collaboration with cross-functional teams and quality assurance.",
              "Utilized Unreal Engine and nFusion's proprietary engine to conceive, script, illuminate, and optimize engaging gameplay environments.",
              "Orchestrated outsourced resource management, technical documentation, build notes, and production schedules to maintain project momentum and quality standards.",
              "Proactively identified and addressed development bottlenecks, driving continuous improvement initiatives to streamline workflows and enhance efficiency.",
            ],
          },
        ],
        Projects: [
          {
            desktop: { top: -200, left: 60 },
            mobile: { top: -150, left: -40 },
            src: "projects-apdict-logo.png",
            id: btoa(221),
            title: "Ascenzio Prediction (APdict)",
            subTitle: "Autonomous Agent Software System",
            dates: "Released:  2/2024",
            content: [
              "<p>Authored an article documenting the development of an autonomous agent system  published on <a href='https://medium.com/@ascenzio/the-evolution-of-software-engineering-integrating-language-models-into-autonomous-agents-305dac7b2d63'>Medium.com</a></p>",
              "Developed an autonomous agent system integrating large-language models (LLMs) and prompting techniques, demonstrating experimentation with LLMs and problem-solving in complex systems development.",
              "Prompt Engineering Techniques: Skilled in various prompting approaches like Chain-of-Thought, ReAct prompting, and Tree-of-Thought prompting, with a deep understanding of leveraging language models effectively within autonomous systems.",
              "To meet project goals, leveraged various tools and frameworks, such as LangChain, OpenAI's API, Meta's models, and Hugging Face's platform.",
            ],
          },
          {
            desktop: { top: -30, left: 55 },
            mobile: { top: 0, left: -40 },
            src: "projects-apocalyptic-adventure-logo.png",
            id: btoa(357),
            title: "Apocalyptic Adventure",
            subTitle: "Virtual Reality Game",
            dates: "Released:  10/2021",
            content: [
              "Independently developed a Virtual Reality game for Oculus Rift S and HTC Vive.",
              "Leveraged Unreal Engine 4 and Blueprint visual scripting language to implement core mechanics, enemy logic, player interactions, inventory, and more.",
              "Created custom artwork using Adobe Photoshop and 3D Studio Max in combination with Epic Game Marketplace content to achieve an overall look and feel.",
              "Produced promotional material, including website, icons, banners, and video trailers.",
            ],
          },
          {
            desktop: { top: 90, left: 55 },
            mobile: { top: 110, left: -40 },
            src: "projects-code-icon.png",
            id: btoa(159),
            title: "Independent Contractor",
            subTitle: "Software Development and UX Design",
            content: [
              "Full-stack development and hosting of custom websites using VanillaJS and frameworks; integrating third-party APIs and open-source libraries.",
              "Customized WordPress templates to client specifications and provided training on managing CMS features.",
              "Environment modeling and texturing using Adobe Photoshop and 3D Studio Max.",
            ],
          },
          {
            desktop: { top: 180, left: 45 },
            mobile: { top: 210, left: -40 },
            src: "projects-2bme-studios-logo.png",
            id: btoa(789),
            title: "2BME Firefighter and 2BME Aviator",
            dates: "Initial Release:  11/2011",
            subTitle: "Educational iOS Games",
            content: [
              "Designed, developed, and released two iOS applications using Unreal Development Kit (UDK).",
              "Learned UnrealScript, an object-oriented scripting language for the UDK.",
              "Created character art and animations, environment models and textures, lighting, and effects.",
              "Developed games website as a content management system by autonomously learning HTML, CSS, PHP, javascript, MySQL, and hosted using Apache HTTP Server.",
            ],
          },
        ],
      },
    };
  },
  methods: {},
};
