<template>
  <div>
    <span v-animate-css.once="animationLogo"><img src="../assets/paul-peccia-logo-animated.gif"/></span>
  </div>
</template>

<script>
export default {
    name: 'intro',
    props: {
        config: {
            default: ()=> {
                return { 
                    delay: 2700,
                    duration: 1000
                }
            }
        }
    },
    data() {
        return {
            animationLogo:{
                classes: 'bounceOut',
                delay: this.config.delay,
                duration: this.config.duration
            }
        }
      }
}
</script>

<style scoped>
div {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
img {
    margin-top: -50px;
    height: 100px;
    width: auto;
}
</style>
