<template>
  <div id="app">
    <intro :config="introProps" v-if="showIntro && !debugging"></intro>
    <div v-else>
      <header>
        <div class="logo" v-animate-css.hover="'headShake'">
          <img
            @click="playIntroAnim()"
            v-animate-css.once="logoFadeIn"
            src="./assets/paul-peccia-logo.png"
          />
        </div>
        <div class="social">
          <ul v-animate-css.once="logoFadeIn">
            <li @click="handelSocial('linkedin')">
              <img width="40px;" src="./assets/linkedin-brands.png" />
            </li>
            <li @click="handelSocial('email')" @mouseover="hoverEmail('email')">
              <img width="40px;" src="./assets/envelope-solid.png" />
            </li>
          </ul>
          <span :class="{ socialFadeIn: socialText !== '' }">{{
            socialText
          }}</span>
        </div>
      </header>
      <LightBox ref="lightBox" :media="images" :showLightBox="false"></LightBox>
      <div class="main">
        <div class="main-container">
          <div class="introduction">
            <div v-animate-css.once="contentFadeIn">
              <span>Hello, my name is</span>
              <h1>Paul Peccia</h1>
              <h2>
                I'm a Principal Software Engineer & Game Developer<br />
                who enjoys transforming ideas into reality with<br />
                innovation and expertise.
              </h2>
            </div>
          </div>
          <div class="portfolio">
            <portfolioContainer
              :title="'EMPLOYMENT'"
              :isLeft="false"
              :activePortfolioObject="activePortfolioObject"
              :isMobile="isMobile"
              :fade="{ duration: 500, delay: 1650 }"
              @imageClicked="portfolioImageClicked"
            ></portfolioContainer>
            <div class="portfolio-description">
              <div v-if="activePortfolioObject" class="portfolio-content">
                <portfolioContent
                  :selected="activePortfolioObject"
                ></portfolioContent>
              </div>
            </div>
            <portfolioContainer
              :title="'PROJECTS'"
              :isLeft="true"
              :activePortfolioObject="activePortfolioObject"
              :isMobile="isMobile"
              :fade="{
                duration: 500,
                delay: getProjectsDelay,
              }"
              @imageClicked="portfolioImageClicked"
            ></portfolioContainer>
          </div>
        </div>
      </div>
      <div class="main-position">
        <div style="display: absolute; top: 0; left: 0">
          <svg
            id="portfolio-svg"
            :width="portfolioAttr.width"
            :height="portfolioAttr.height"
            style="min-width: 650px !important;"
          ></svg>
          <svg
            id="honeycomb-svg"
            width="100%"
            height="500px"
            style="min-width: 650px !important;"
            :style="{
              top: isMobile && activePortfolioObject ? '1650px' : '850px',
            }"
          ></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import intro from "./components/intro.vue";
import portfolioContent from "./components/portfolioContent.vue";
import portfolioContainer from "./components/portfolioContainer.vue";
import d3Mixin from "./mixins/d3Mixin.js";
import portfolio from "./mixins/portfolio.js";

import LightBox from "vue-it-bigger"; //https://github.com/haiafara/vue-it-bigger

export default {
  name: "App",
  mixins: [d3Mixin, portfolio],
  components: {
    intro,
    portfolioContent,
    portfolioContainer,
    LightBox,
  },
  data() {
    return {
      debugging: false,
      socialText: "",
      fadeEmail: false,
      showIntro: true,
      images: [
        {
          thumb: require("./assets/screenshots/apocalyptic-adventure-screenshot-1-thumb.jpg"),
          src: require("./assets/screenshots/apocalyptic-adventure-screenshot-1.jpg"),
          // srcset: '...'  Optional for displaying responsive images
          data: "3:2",
        },
        {
          thumb: require("./assets/screenshots/paul-peccia-projects-website-design-thumb.jpg"),
          src: require("./assets/screenshots/paul-peccia-projects-website-design.jpg"),
          // srcset: '...'  Optional for displaying responsive images
          data: "1:3",
        },
        {
          thumb: require("./assets/screenshots/2bme-studios-firefighter-aviator-thumb.jpg"),
          src: require("./assets/screenshots/2bme-studios-firefighter-aviator.jpg"),
          // srcset: '...'  Optional for displaying responsive images
          data: "3:1",
        },
        {
          thumb: require("./assets/screenshots/nfusion-interactive-game-screenshots-thumb.jpg"),
          src: require("./assets/screenshots/nfusion-interactive-game-screenshots.jpg"),
          // srcset: '...'  Optional for displaying responsive images
          data: "4:2",
        },
      ],
      introProps: {
        delay: 2700,
        duration: 1000,
      },
      logoFadeIn: {
        classes: "bounceIn",
        duration: 700,
      },
      contentFadeIn: {
        classes: "fadeIn",
        duration: 1500,
      },
      selectFadeIn: {
        classes: "bounceIn",
        delay: 3400,
        duration: 1500,
      },
      pageHasBeenResized: false,
      activePortfolioObject: null,
      portfolioAttr: {
        width: "100%",
        height: "700px",
      },
    };
  },
  computed: {
    generateImageRandomness() {
      let rand = Math.random() * 20;
      return {
        transform: `rotate(${Math.random() * 90}deg)`,
        top: rand + "px",
        left: rand + "px",
      };
    },
    getProjectsDelay() {
      return window.innerWidth < this.mobileWidth ? 1800 : 2200;
    },
  },
  mounted() {
    if (this.debugging) {
      this.initPage();
    } else {
      setTimeout(
        this.initPage,
        this.introProps.delay + this.introProps.duration
      );
    }
  },
  methods: {
    honeycombClicked(id) {
      let [, x, y] = id.match(/(\d)-(\d)/) || ["", "0", "0"];
      let imgIndex = 0;
      const [clicked] = this.images.filter((img, idx) => {
        if (img.data === `${x}:${y}`) {
          imgIndex = idx;
          return true;
        }
        return false;
      });

      this.$refs.lightBox.showImage([imgIndex]);
      return clicked;
    },
    handelSocial(type) {
      switch (type) {
        case "email":
          window.open("mailto:paul@peccia.net", "_self");
          break;
        case "linkedin":
          window.open(
            "https://www.linkedin.com/in/paul-peccia-8761003/",
            "_self"
          );
          break;
      }
    },
    hoverEmail() {
      this.fadeEmail = false;
      if (this.socialText === "") {
        this.socialText = "Paul@Peccia.net";
      }
    },
    async delay(duration) {
      return new Promise((resolve) => setTimeout(resolve, duration));
    },
    async initPage() {
      this.showIntro = false;

      window.addEventListener("resize", () => {
        this.pageHasBeenResized = true;
        this.createGallery();
        this.redrawChoice();
      });

      if (!this.portfolioD3) {
        await this.delay(200);
        this.initD3();
      }
    },
    playIntroAnim() {
      this.fadeEmail = false;
      this.socialText = "";
      this.activePortfolioObject = null;
      this.pageHasBeenResized = false;
      this.resetGraph();
      this.showIntro = true;
      setTimeout(
        this.initPage,
        this.introProps.delay + this.introProps.duration
      );
    },
    portfolioImageClicked(clickedImage) {
      if (!this.activePortfolioObject) {
        this.activePortfolioObject = clickedImage;
        this.lineBlast();
      } else {
        this.activePortfolioObject = clickedImage;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@600&family=Raleway&display=swap");
@import url("../node_modules/vue-it-bigger/src/components/style.css");
@import url("./main.css");
</style>
