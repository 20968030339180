import * as d3 from "d3";

export default {
  data() {
    return {
      portfolioD3: null,
      isMobile: false,
      mobileWidth: 860,
      lineColor: "rgba(4,11,36,1)",
      lineWidth: "3px",
    };
  },
  methods: {
    resetGraph() {
      d3.selectAll("#portfolio-d3").remove();
      this.portfolioD3 = null;
    },
    displayMobile() {
      let threshold = this.portfolioD3.node().clientWidth + 16;
      return this.mobileWidth > threshold;
    },
    initD3() {
      this.portfolioD3 = d3.select("#portfolio-svg");
      let { width } = this.d3GetDimensions();

      if (width > this.mobileWidth) {
        this.introAnim();
        this.createGallery();
      } else {
        this.introAnimMobile();
        this.createGallery();
      }
    },
    /* Creates Honeycomb Gallery */
    async createGallery() {
      const svg = d3.select("#honeycomb-svg");
      const width = svg.node().clientWidth;
      const height = svg.node().clientHeight;
      svg.selectAll('[id^="poly"]').remove();

      let side = 55; // Defines the size of honeycomb
      let start = { x: side, y: height - 50 };

      let heightCount = Math.round(height / (side * 2));
      let widthCount = Math.round(width / (side * 2));

      const yDefault = 0.3;
      const xDefault = 0.2;
      let drift = { x: xDefault, y: yDefault };

      let offset = 0;
      for (let w = 1; widthCount > w; w++) {
        drift.y = yDefault;
        if (w === 1) {
          offset = width / 2 - side;
        } else if (w % 2) {
          offset = width / 2 - side + side * 2 * (w / 2);
          offset = offset - side;
        } else {
          drift.x = drift.x - 0.05;
          offset = width / 2 - side - side * 2 * (w / 2);
        }

        for (let h = 0; h < heightCount; h++) {
          const id = `${w}-${h + 1}`;
          const combHeight = side + side / 2;
          let iStart = {};
          drift.y = drift.y - 0.05;

          if (h % 2) {
            iStart = {
              x: start.x + side + offset,
              y: h == 0 ? start.y : start.y - combHeight * h,
            };
          } else {
            iStart = {
              x: start.x + offset,
              y: h == 0 ? start.y : start.y - combHeight * h,
            };
          }

          var src = this.images.filter((i) => {
            const loc = /([0-9])+:([0-9]+)/.exec(i.data);
            return loc && w === +loc[1] && 1 + h === +loc[2];
          });

          if (src.length) {
            this.drawHoneycombGalleryItem(side, iStart, id, src);
          } else {
            let opacity = Math.min(drift.x, drift.y);
            if (opacity > 0.0) {
              this.drawHoneycombPoly(side, iStart, id, opacity);
            }
          }
        }
      }
    },
    /* Draws Honeycomb Gallery Item and Bind Event Listeners */
    drawHoneycombGalleryItem(
      side = 50,
      start = { x: 0, y: 0 },
      id = "",
      [src] = null
    ) {
      var data = [
        { x: start.x, y: start.y },
        { x: start.x - side, y: start.y - side / 2 },
        { x: start.x - side, y: start.y - (side / 2 + side) },
        { x: start.x, y: start.y - side * 2 },
        { x: start.x + side, y: start.y - (side / 2 + side) },
        { x: start.x + side, y: start.y - side / 2 },
      ];

      const svg = d3.select("#honeycomb-svg");
      svg.selectAll(`poly${id}`).remove();
      svg.selectAll(`poly${id}-defs`).remove();

      // Define Clipping Path and Polygon
      svg
        .selectAll("poly")
        .data([data])
        .enter()
        .append("defs")
        .attr("id", `poly${id}-defs`)
        .append("clipPath")
        .attr("id", `poly${id}-mask`)
        .attr("x", () => data[0].x + "px")
        .attr("y", () => data[0].y + "px")
        .append("polygon")
        .attr("id", "poly" + id)
        .attr("points", (d) => {
          return d.map((d) => {
            return [d.x, d.y].join(",");
          });
        });

      const poly = svg.append("g").attr("id", `poly${id}`);
      // Add Image and Mouse Events
      poly
        .selectAll("poly")
        .data([data])
        .enter()
        .append("image")
        .attr("id", `poly${id}-image`)
        .attr("xlink:href", src.thumb)
        .attr("width", `${side * 2}px`)
        .attr("height", `${side * 2}px`)
        .attr("x", () => data[0].x - side + "px")
        .attr("y", () => data[0].y - side * 2 + "px")
        .attr("opacity", 0.3)
        .attr("clip-path", `url(#poly${id}-mask)`)
        .on("mouseenter", () => {
          d3.select(`#poly${id}-image`)
            .transition()
            .ease(d3.easeLinear)
            .duration(200)
            .attr("opacity", 1);
        })
        .on("mouseleave", () => {
          d3.select(`#poly${id}-image`)
            .transition()
            .ease(d3.easeLinear)
            .duration(200)
            .attr("opacity", 0.3);
        })
        .on("click", () => {
          this.honeycombClicked(id);
        });
      // Add Polygon for Honeycomb Border Effects
      poly
        .selectAll("poly")
        .data([data])
        .enter()
        .append("polygon")
        .attr("id", `poly${id}-event`)
        .attr("points", (d) => {
          return d.map((d) => {
            return [d.x, d.y].join(",");
          });
        })
        .style("stroke", "#00a8ff")
        .style("stroke-width", 2)
        .attr("fill", "none");
    },
    // Add Honeycomb Polygon
    drawHoneycombPoly(
      side = 50,
      start = { x: 0, y: 0 },
      id = "",
      opacity = 0.01
    ) {
      var data = [
        { x: start.x, y: start.y },
        { x: start.x - side, y: start.y - side / 2 },
        { x: start.x - side, y: start.y - (side / 2 + side) },
        { x: start.x, y: start.y - side * 2 },
        { x: start.x + side, y: start.y - (side / 2 + side) },
        { x: start.x + side, y: start.y - side / 2 },
      ];

      const svg = d3.select("#honeycomb-svg");
      svg.selectAll(`poly${id}`).remove();

      svg
        .selectAll("poly")
        .data([data])
        .enter()
        .append("polygon")
        .attr("id", `poly${id}`)
        .attr("points", (d) => {
          return d.map((d) => {
            return [d.x, d.y].join(",");
          });
        })
        .style("stroke", "#00a8ff")
        .style("stroke-width", 2)
        .attr("fill", "rgba(0,0,0,0.01)")
        .attr("opacity", () => {
          return Math.random() > 0.5 ? opacity : opacity * 0.5;
        })
        .on("mouseenter", () => {
          d3.select(`#poly${id}`)
            .transition()
            .ease(d3.easeLinear)
            .duration(200)
            .attr("opacity", "0.77")
            .transition()
            .ease(d3.easeLinear)
            .duration(200)
            .attr("opacity", () => {
              return Math.random() > 0.5 ? opacity : opacity * 0.5;
            });
        });
    },
    d3GetDimensions() {
      if (this.portfolioD3) {
        this.isMobile = this.displayMobile();
        return {
          width: this.portfolioD3.node().clientWidth,
          height: this.portfolioD3.node().clientHeight,
        };
      } else {
        return {
          width: 0,
          height: 0,
        };
      }
    },
    d3ChoiceData() {
      let { width, height } = this.d3GetDimensions();
      let center = width / 2;
      let middle = height / 2;

      // Left half 0-7, Right half 8-15
      return [
        [{ x: -200, y: 5 }, { x: center - 50, y: 5 }, 1000],
        [{ x: center - 50, y: 5 }, { x: center, y: 55 }, 100],
        [{ x: center, y: 55 }, { x: center, y: middle - 50 }, 300],
        [
          { x: center, y: middle - 50 },
          { x: center - 100, y: middle - 50 },
          200,
        ],
        ///[{x: (center-100), y: (middle-50)}, {x: (center-100), y: (middle-100)}, 100],
        [
          { x: center - 100, y: middle - 100 },
          { x: center - 170, y: middle - 25 },
          150,
        ],
        [
          { x: center - 170, y: middle - 25 },
          { x: center - 100, y: middle + 50 },
          150,
        ],
        [
          { x: center - 100, y: middle + 50 },
          { x: center - 100, y: middle },
          100,
        ],
        [{ x: center - 100, y: middle }, { x: center - 50, y: middle }, 200],
        [{ x: center + 50, y: middle }, { x: center + 100, y: middle }, 200],
        [
          { x: center + 100, y: middle },
          { x: center + 100, y: middle - 50 },
          100,
        ],
        [
          { x: center + 100, y: middle - 50 },
          { x: center + 175, y: middle + 25 },
          150,
        ],
        [
          { x: center + 175, y: middle + 25 },
          { x: center + 100, y: middle + 100 },
          150,
        ],
        //[{x: (center+100), y: (middle+100)}, {x: (center+100), y: (middle+50)}, 100],
        [
          { x: center + 100, y: middle + 50 },
          { x: center, y: middle + 50 },
          200,
        ],
        [{ x: center, y: middle + 50 }, { x: center, y: middle + 285 }, 300],
        [
          { x: center, y: middle + 285 },
          { x: center * 2, y: middle + 285 },
          1000,
        ],
      ];
    },
    d3ChoiceDataMobile() {
      let { width, height } = this.d3GetDimensions();
      let center = width / 2;
      let middle = height / 2;

      return [
        [{ x: -200, y: 5 }, { x: center, y: 5 }, 1000],
        [{ x: center, y: 5 }, { x: center, y: middle - 80 }, 300],

        [
          { x: center - 25, y: middle - 60 },
          { x: center - 75, y: middle - 5 },
          150,
        ],
        [
          { x: center - 75, y: middle - 5 },
          { x: center - 25, y: middle + 50 },
          100,
        ],

        [
          { x: center + 25, y: middle - 60 },
          { x: center + 75, y: middle - 5 },
          150,
        ],
        [
          { x: center + 75, y: middle - 5 },
          { x: center + 25, y: middle + 50 },
          100,
        ],

        [{ x: center, y: middle + 70 }, { x: center, y: middle + 225 }, 300],
        [
          { x: center, y: middle + 225 },
          { x: center * 2, y: middle + 225 },
          1000,
        ],
      ];
    },
    async introAnim() {
      let choices = this.d3ChoiceData();
      /* Intro line */
      await this.animLine(choices[0]);
      await this.animLine(choices[1]);
      await this.animLine(choices[2]);
      await this.animLine(choices[3]);
      await this.animLine(choices[4]);
      await this.animLine(choices[5]);
      await this.animLine(choices[6]);
      await this.animLine(choices[7]);
      await this.animLine(choices[8]);
      await this.animLine(choices[9]);
      await this.animLine(choices[10]);
      await this.animLine(choices[11]);
      await this.animLine(choices[12]);
      await this.animLine(choices[13]);
      await this.animLine(choices[14]);

      if (this.pageHasBeenResized || this.activePortfolioObject) return;

      this.animSelectText();

      // Redraw after animation to show pulse css animation
      await new Promise((resolve) => setTimeout(resolve, 500));
      d3.selectAll("#portfolio-d3").remove();
      this.redrawChoice();
      choices.forEach((c) => this.drawLine(c));
    },
    async introAnimMobile() {
      let choices = this.d3ChoiceDataMobile();
      await this.animLine(choices[0]);
      await this.animLine(choices[1]);
      await this.animLine(choices[2]);
      await this.animLine(choices[3]);
      await this.animLine(choices[4]);
      await this.animLine(choices[5]);
      await this.animLine(choices[6]);
      await this.animLine(choices[7]);

      if (this.pageHasBeenResized || this.activePortfolioObject) return;

      this.animSelectText();

      // Redraw after animation to show pulse css animation
      await new Promise((resolve) => setTimeout(resolve, 500));
      d3.selectAll("#portfolio-d3").remove();
      this.redrawChoice();
      choices.forEach((c) => this.drawLine(c));
    },
    redrawChoice() {
      d3.selectAll("#portfolio-d3").remove();

      if (!this.activePortfolioObject) {
        this.redrawSelectText();
        if (this.isMobile) {
          let choices = this.d3ChoiceDataMobile();
          choices.forEach((c) => this.drawLine(c));
        } else {
          let choices = this.d3ChoiceData();
          choices.forEach((c) => this.drawLine(c));
        }
      } else {
        if (this.isMobile) {
          let choices = this.d3ChoiceDataMobile();
          this.drawLine(choices[0]);
          this.drawLine(choices[7]);
        } else {
          let choices = this.d3ChoiceData();
          this.drawLine(choices[0]);
          this.drawLine(choices[14]);
        }
      }
    },
    animSelectText() {
      if (this.pageHasBeenResized || this.activePortfolioObject) return;

      let { width, height } = this.d3GetDimensions();
      width = width / 2;
      height = height / 2;
      this.portfolioD3
        .append("text")
        .text("Select")
        .attr("id", "portfolio-d3")
        .attr("text-anchor", "middle")
        .attr("x", width)
        .attr("y", height - 12)
        .attr("fill", "white")
        .transition()
        .ease(d3.easeLinear)
        .duration(200)
        .attr("font-size", 26)
        .transition()
        .ease(d3.easeBackOut)
        .duration(200)
        .attr("font-size", 18);
      this.portfolioD3
        .append("text")
        .text("One")
        .attr("id", "portfolio-d3")
        .attr("text-anchor", "middle")
        .attr("x", width)
        .attr("y", height + 12)
        .attr("fill", "white")
        .transition()
        .ease(d3.easeLinear)
        .duration(200)
        .attr("font-size", 26)
        .transition()
        .ease(d3.easeBackIn)
        .duration(200)
        .attr("font-size", 18);
    },
    redrawSelectText() {
      let { width, height } = this.d3GetDimensions();
      width = width / 2;
      height = height / 2;
      this.portfolioD3
        .append("text")
        .text("Select")
        .attr("id", "portfolio-d3")
        .attr("text-anchor", "middle")
        .attr("x", width)
        .attr("y", height - 12)
        .attr("fill", "white")
        .attr("font-size", 18);
      this.portfolioD3
        .append("text")
        .text("One")
        .attr("id", "portfolio-d3")
        .attr("text-anchor", "middle")
        .attr("x", width)
        .attr("y", height + 12)
        .attr("fill", "white")
        .attr("font-size", 18);
    },
    lineBlast() {
      d3.selectAll("#portfolio-d3").remove();

      if (this.isMobile) {
        let choices = this.d3ChoiceDataMobile();
        this.drawLine(choices[0]);
        this.drawLine(choices[7]);

        for (let i = 1; i < 7; i++) {
          this.animLineReverse(choices[i]);
        }
      } else {
        let choices = this.d3ChoiceData();
        this.drawLine(choices[0]);
        this.drawLine(choices[14]);
        for (let i = 1; i < 14; i++) {
          this.animLineReverse(choices[i]);
        }
      }
    },
    async animLine([
      start = { x: 0, y: 0 },
      finish = { x: 0, y: 0 },
      duration = 1000,
    ]) {
      if (this.pageHasBeenResized || this.activePortfolioObject) return;

      this.portfolioD3
        .append("line")
        .attr("id", "portfolio-d3")
        .attr("class", "d3-line")
        .attr("x1", start.x)
        .attr("y1", start.y)
        .attr("x2", start.x)
        .attr("y2", start.y)
        .transition()
        .ease(d3.easeLinear)
        .duration(duration)
        .attr("x2", finish.x)
        .attr("y2", finish.y);

      this.portfolioD3
        .append("line")
        .attr("id", "portfolio-d3")
        .style("stroke", this.lineColor)
        .style("stroke-width", this.lineWidth)
        .attr("x1", start.x)
        .attr("y1", start.y)
        .attr("x2", start.x)
        .attr("y2", start.y)
        .transition()
        .ease(d3.easeLinear)
        .duration(duration)
        .attr("x2", finish.x)
        .attr("y2", finish.y);

      return new Promise((resolve) => setTimeout(resolve, duration));
    },
    animLineReverse([
      start = { x: 0, y: 0 },
      finish = { x: 0, y: 0 },
      duration = 1000,
    ]) {
      if (this.pageHasBeenResized) return;

      this.portfolioD3
        .append("line")
        .attr("id", "portfolio-d3")
        .attr("class", "d3-line")
        .attr("x1", start.x)
        .attr("y1", start.y)
        .attr("x2", finish.x)
        .attr("y2", finish.y)
        .transition()
        .ease(d3.easeLinear)
        .duration(duration)
        .attr("x1", finish.x)
        .attr("y1", finish.y)
        .attr("x2", finish.x)
        .attr("y2", finish.y);

      this.portfolioD3
        .append("line")
        .attr("id", "portfolio-d3")
        .style("stroke", this.lineColor)
        .style("stroke-width", this.lineWidth)
        .attr("x1", start.x)
        .attr("y1", start.y)
        .attr("x2", finish.x)
        .attr("y2", finish.y)
        .transition()
        .ease(d3.easeLinear)
        .duration(duration)
        .attr("x1", finish.x)
        .attr("y1", finish.y)
        .attr("x2", finish.x)
        .attr("y2", finish.y);
    },
    drawLine([start = { x: 0, y: 0 }, finish = { x: 0, y: 0 }]) {
      if (this.activePortfolioObject) {
        this.portfolioD3
          .append("line")
          .attr("id", "portfolio-d3")
          .attr("class", "d3-line")
          .attr("x1", start.x)
          .attr("y1", start.y)
          .attr("x2", finish.x)
          .attr("y2", finish.y);
      } else {
        this.portfolioD3
          .append("line")
          .attr("id", "portfolio-d3")
          .attr("class", "d3-line-anim")
          .attr("x1", start.x)
          .attr("y1", start.y)
          .attr("x2", finish.x)
          .attr("y2", finish.y);
      }

      this.portfolioD3
        .append("line")
        .attr("id", "portfolio-d3")
        .style("stroke", this.lineColor)
        .style("stroke-width", this.lineWidth)
        .attr("x1", start.x)
        .attr("y1", start.y)
        .attr("x2", finish.x)
        .attr("y2", finish.y);
    },
  },
};
