<template>
  <div :id="`portfolio-image-${id}`" class="portfolio-image">
    <img
      v-animate-css.once="'pulse'"
      v-animate-css.hover="'headShake'"
      :src="getImgUrl(config.src)"
      :alt="config.src"
      :style="imageStyle"
    />
  </div>
</template>
<script>
export default {
  props: {
    isLeft: {
      default: false,
    },
    config: {
      default: () => {
        return {};
      },
    },
    selected: {
      default: null,
    },
    isMobile: {
      default: false,
    },
  },
  data() {
    return {
      selfElement: null,
      id: this.config.id,
      isSelected: false,
      imageStyle: null,
    };
  },
  computed: {
    rightStyle() {
      return {
        transform: `rotate(0deg) scale3d(1, 1, 1)`,
        top: this.isMobile
          ? this.config.mobile.top + "px"
          : this.config.desktop.top + "px",
        right: this.isMobile
          ? this.config.mobile.left + "px"
          : this.config.desktop.left + "px",
        "z-index": this.config.index,
        opacity: 0.6,
      };
    },
    leftStyle() {
      return {
        transform: `rotate(0deg) scale3d(1, 1, 1)`,
        top: this.isMobile
          ? this.config.mobile.top + "px"
          : this.config.desktop.top + "px",
        left: this.isMobile
          ? this.config.mobile.left + "px"
          : this.config.desktop.left + "px",
        "z-index": this.config.index,
        opacity: 0.6,
      };
    },
  },
  watch: {
    isMobile() {
      this.imageStyle = this.getStyle();
      if (this.isSelected) {
        this.imageStyle.opacity = 0.9;
        this.imageStyle.transform = `rotate(0deg) scale3d(1.1, 1.1, 1.1)`;
      } else {
        this.imageStyle.opacity = 0.6;
      }
    },
    selected(selected) {
      this.imageStyle = this.getStyle();
      if (selected.id === this.config.id) {
        this.imageStyle.opacity = 0.9;
        this.imageStyle.transform = `rotate(0deg) scale3d(1.1, 1.1, 1.1)`;
        this.isSelected = true;
      } else {
        this.imageStyle.opacity = 0.6;
        if (this.isSelected) {
          this.imageStyle.transform = `rotate(${Math.random() *
            (Math.random() > 0.5 ? 20.0 : -20.0)}deg)`;
        }
        this.isSelected = false;
      }
    },
  },
  mounted() {
    this.selfElement = document.getElementById(`portfolio-image-${this.id}`);
    if (this.selfElement) {
      this.imageStyle = this.isLeft ? this.leftStyle : this.rightStyle;
      this.selfElement.addEventListener("mouseenter", this.onEnter);
      this.selfElement.addEventListener("mouseleave", this.onLeave);
      this.selfElement.addEventListener("mousedown", this.onClick);
    } else {
      console.log("Failed to get self");
    }
  },
  methods: {
    getStyle() {
      return this.isLeft ? this.leftStyle : this.rightStyle;
    },
    getImgUrl(src) {
      var images = require.context("../assets/", false, /\.png$/);
      return images("./" + src);
    },
    onEnter() {
      this.imageStyle.opacity = 0.9;
    },
    onLeave() {
      if (!this.isSelected) {
        this.imageStyle.opacity = 0.6;
      }
    },
    onClick() {
      this.$emit("click", this.id);
    },
  },
};
</script>
<style>
.portfolio-image img {
  position: absolute;
}
</style>
